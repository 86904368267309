import React from 'react';
import generateSectionUrl from 'utils/generateSectionUrl';
import get from 'lodash/get';
import { Button, Img } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import Language from 'constants/Language';
import generateArticleUrl from 'utils/generateArticleUrl';
import { ArticleLink, RecentArticleLink } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';
import { convertSanityURLToImageCDNUrl } from 'utils/imageBuilder';
import { useIsFiction } from 'src/hooks/useFiction';

interface StoreProps {
  sections: RecentArticleLink[][];
}

const RecentArticlesBySection: React.FC<StoreProps> = ({ sections = [] }) => {
  const isFiction = useIsFiction();
  return (
    <div className="RecentArticlesBySection inner-content-max-width content-padding-x mxauto vw100 no-display-in-print">
      <div className="RecentArticlesBySection__divider inner-content-max-width content-padding-x mxauto"></div>
      <div className="RecentArticlesBySection__section-container w100">
        {sections.map((articlesBySection: ArticleLink[]) => {
          const section = get(articlesBySection, '[0].section', {});
          const sectionSlug = get(section, 'slug', '');
          const sectionTitle = get(section, 'title', '');
          const iconColor = isFiction ? 'blue' : 'dark';
          const imageSrc = get(section, `images.${iconColor}.src`, '');
          const imageAlt = get(section, `images.${iconColor}.alt`, '');
          return (
            <div
              key={`section-${sectionSlug}`}
              className="RecentArticlesBySection__section w100 mb1_5 pr1_25"
            >
              <div className="RecentArticlesBySection__logo-container pb1 sm:pb1_5 w100 flex items-baseline justify-between">
                <Img
                  className="RecentArticlesBySection__section-logo"
                  src={convertSanityURLToImageCDNUrl(imageSrc)}
                  alt={imageAlt || sectionTitle}
                />
                <Button
                  to={generateSectionUrl(sectionSlug)}
                  ariaLabel={Language.t('Global.sectionButtonAriaLabel', {
                    sectionTitle: sectionTitle,
                  })}
                  className="text-center move-image-right-on-hover color-black graebenbach font-400 text-article-details-sm text-decoration-none trigger-RecentArticlesBySection-SectionAllLink"
                >
                  {Language.t('Global.seeAll')}
                  <span className="inline-block ml_25 button-move-arrow">
                    &rarr;&#xFE0E;
                  </span>
                </Button>
              </div>

              <div className="RecentArticlesBySection__list mb1_5">
                {articlesBySection.map((article: ArticleLink) => {
                  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
                  const linkTo = isFeatureArticle ? `/feature/${article.slug}` : generateArticleUrl(article);
                  
                  return (
                    <div
                      key={`recent-article-${article.id}`}
                      className="RecentArticlesBySection__article color-black"
                    >
                      <Button
                        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                          title: article.title,
                        })}
                        className="trigger-RecentArticlesBySection-ArticleLink"
                        to={linkTo}
                        wrap={true}
                      >
                        <span className="RecentArticlesBySection__article__title itc-cushing color-black font-600 linked-border-bottom-sm-on-hover-red">
                          {curlyQuotes(article.title)}
                        </span>
                      </Button>
                      {article.authors && !!article.authors.length && (
                        <AuthorButtons
                          className="RecentArticlesBySection__article__authors block color-black"
                          authors={article.authors}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentArticlesBySection;
